import { Button } from "@9amhealth/shared";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import DataHubBgImg from "src/ui/assets/patterns/dataHubBg.jpg";
import DataHubCubit from "./DataHubCubit";
import VitalSignTabs from "./VitalSignTabs";

//#region Styled Components
const DataHubWrapper = styled.div`
  position: relative;
  margin: -1rem calc(-1 * var(--space-md)) 1.5rem;
  padding: 2rem 1.5rem 1.25rem;
  box-shadow: var(--light-shadow);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #fff;
    z-index: -1;
  }

  @media screen and (min-width: ${APP_CONTENT_WIDTH}px) {
    margin: 0 0 2rem;
    border-radius: 0.5rem;
  }
`;

const StyledButton = styled(Button)`
  background: var(--color-gray-fog);
  border-radius: 0.5rem;
  margin-top: 0.75rem;
  width: 100%;
  color: var(--color-charcoal-80);
`;

const StyledImg = styled.img`
  position: absolute;
  inset: 0;
  z-index: -1;
`;

const ChartWrapper = styled.div`
  height: 389px;
  background: grey;
  opacity: 0.15;
  margin: 0 -1.5rem;
`;
//#endregion

const DataHub: FC = () => {
  const [{ activeVitalSignLoincCode }] = useBloc(DataHubCubit);
  const navigate = useNavigate();

  return (
    <DataHubWrapper>
      <StyledImg src={DataHubBgImg} />
      <h2>Data Hub</h2>

      {/* Clickable tabs */}
      <VitalSignTabs />

      {/* Status */}
      <div>
        <p style={{ margin: 0 }}>[status message placeholder]</p>
        <p style={{ margin: 0 }}>
          [status time range and percentage placeholder]
        </p>
      </div>

      <nine-spacer s="sm" />

      {/* Chart */}
      <ChartWrapper>[chart and time range placeholder]</ChartWrapper>

      {/* Explore button */}
      <StyledButton
        hideArrow
        onPress={() => {
          navigate(`/app/lab-results/list-codes/${activeVitalSignLoincCode}`);
        }}
      >
        Explore Data
      </StyledButton>
    </DataHubWrapper>
  );
};

export default DataHub;
