import {
  PurchaseItemResponse,
  SubscriptionDetailsResponse
} from "@9amhealth/openapi";
import { NineButton } from "@9amhealth/wcl/generated/react";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { deviceTypeToImageMap } from "src/constants/devices";
import {
  BLOOD_SPOT_SKU,
  CDCDPP_PROGRAM_ITEM_SKU,
  FUNNEL_QUESTIONNAIRE_MEDICAL,
  MEDICAL_ASSESSMENT_ID,
  PHLEBOTOMY_SKU
} from "src/constants/misc";
import { checkItemType } from "src/lib/checkItemType";
import { getMedicationItemSig } from "src/lib/getMedicationItemSig";
import openVcfFile from "src/lib/openVcfFile";
import translate from "src/lib/translate";
import DevicesCubit from "src/state/DevicesCubit/DevicesCubit";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import {
  ItemType,
  type SubscriptionInterval
} from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import UserCubit from "src/state/UserCubit/UserCubit";
import { useBloc, userState } from "src/state/state";
import pillBottle from "src/ui/assets/images/iso-meds-vial.jpg";
import devicesBox from "src/ui/assets/images/medical-devices-box.jpg";
import PatientCareImage from "src/ui/assets/images/patient-care.jpg";
import RxDietThumbnailImage from "src/ui/assets/images/rxdiet-thumbnail.jpg";
import LabTestsImage from "src/ui/assets/in_lab_blood_draw.jpg";
import HomePhlebotomyImage from "src/ui/assets/phlebotomy_square_new.jpg";
import TestStripsAndGlucometerImage from "src/ui/assets/testStrips_glucomether_square_new.jpg";
import { DeviceRowItem } from "src/ui/components/DeviceRowItem/DeviceRowItem";
import Emoji from "src/ui/components/Emoji/Emoji";
import FakeButton from "src/ui/components/FakeButton/FakeButton";
import { MedicationRowItem } from "src/ui/components/MedicationRowItem/MedicationRowItem";
import type { NextStepsCardProps } from "src/ui/components/NextStepsCard/NextStepsCard";
import NextStepsCard from "src/ui/components/NextStepsCard/NextStepsCard";
import { StyledReadMore } from "src/ui/components/ReadMore/ReadMore";
import ShipmentsList from "src/ui/components/ShipmentsList/ShipmentsList";
import Translate from "src/ui/components/Translate/Translate";
import { Pad } from "src/ui/styled/Pad";
import { LegacyCard } from "src/ui/styled/X";
import externalItemType = PurchaseItemResponse.externalItemType;
import { ProfileAttributesKeys } from "../../../../../state/UserCubit/UserCubit";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import TaskManagementBloc from "src/state/TaskManagementBloc/TaskManagementBloc";

export const FullSubscriptionPlanOverviewContent: FC = () => {
  const [
    { allSubscriptions = [] },
    {
      getParsedMeds,
      getParsedSupplies,
      checkHasLabs,
      checkHasPlan,
      checkHasTestStrips,
      getFullSubscription,
      removeTestStripsDraftSubscriptionCoupons
    }
  ] = useBloc(SubscriptionCubit);

  const [{ answeredQuestionnaires }] = useBloc(UserCubit);
  const navigate = useNavigate();

  const subscription = useMemo(() => {
    const active = getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE
    ]);
    const inReview = getFullSubscription([
      SubscriptionDetailsResponse.status.IN_REVIEW
    ]);
    const paused = getFullSubscription([
      SubscriptionDetailsResponse.status.PAUSED
    ]);
    const draft = getFullSubscription([
      SubscriptionDetailsResponse.status.DRAFT
    ]);

    return active ?? inReview ?? paused ?? draft;
  }, [allSubscriptions]);

  const isFullSubscriptionInReview =
    subscription?.status === SubscriptionDetailsResponse.status.IN_REVIEW;
  const parsedMeds = getParsedMeds(subscription?.id);
  const showMeds = parsedMeds.length > 0;
  const parsedSupplies = getParsedSupplies(subscription?.id);
  const showSupplies = parsedSupplies.length > 0;
  const hasLabs = checkHasLabs(subscription?.id);
  const hasPhlebotomy = hasLabs?.sku === PHLEBOTOMY_SKU;
  const hasPlan = checkHasPlan(subscription?.id);
  const hasTestStrips = checkHasTestStrips();
  const showLabs = Boolean(hasLabs);
  const showAccessToCareTeam = hasPlan;

  const showTestStrips = Boolean(hasTestStrips);
  const testStripsQuantity = hasTestStrips?.quantity;
  const testStripsMeasurementFrequency = (testStripsQuantity ?? 100) / 100;

  const isCDCDPPSubscription = subscription?.purchaseItems.some(
    (item) => item.sku === CDCDPP_PROGRAM_ITEM_SKU
  );

  // Show RxDiet nutrition program for everyone except DPP
  const showNutritionProgram = !isCDCDPPSubscription;
  // show lifestyle coaching 
  const [taskState, {loadProgramTasks, getProgramTasks}] = useBloc(TaskManagementBloc)
  const showLifestyleCoaching = useMemo(() =>
    getProgramTasks(KnownProgram.LIFEBALANCE).length > 0
  , [taskState.tasks]);

  const [{ devices }, { getUserDevices }] = useBloc(DevicesCubit, {
    create: () => new DevicesCubit()
  });

  useEffect(() => {
    void getUserDevices();
    void loadProgramTasks(KnownProgram.LIFEBALANCE);
  }, []);

  const storedDevices = devices.filter(
    (device) => device.prescriptionId === null
  );

  const showNextSteps = !showMeds && !showSupplies;

  const prescryptiveMedicalQuestionnaireAnswered = useMemo(
    () =>
      answeredQuestionnaires?.some(
        (questionnaire) =>
          questionnaire.questionnaireRef.id === FUNNEL_QUESTIONNAIRE_MEDICAL ||
          questionnaire.questionnaireRef.id === MEDICAL_ASSESSMENT_ID
      ),
    [answeredQuestionnaires]
  );
  const { profileAttributes } = userState.state;
  const hasCompletedInitialConsult =
    !!profileAttributes?.[ProfileAttributesKeys.hasCompletedInitialOrFollowup];

  const medicalAssessmentActive = !prescryptiveMedicalQuestionnaireAnswered;
  const medicalReviewActive =
    !hasCompletedInitialConsult &&
    Boolean(prescryptiveMedicalQuestionnaireAnswered);

  const nextStepsCardProps: NextStepsCardProps = {
    steps: [
      { status: "done", title: <Translate msg="task.programEnrollment" /> },
      {
        status: medicalAssessmentActive ? "in-progress" : "done",
        title: <Translate msg="task.medicalAssessment" />,
        subtitle:
          medicalAssessmentActive &&
          translate("task.completeMedicalAssessment"),
        content: medicalAssessmentActive && (
          <NineButton
            onClick={(): void =>
              navigate(`/app/questionnaire/${MEDICAL_ASSESSMENT_ID}`)
            }
          >
            <Translate msg="task.medicalAssessment" />
          </NineButton>
        )
      },
      {
        status: hasCompletedInitialConsult
          ? "done"
          : medicalReviewActive
            ? "in-progress"
            : "to-do",
        title: <Translate msg="task.medicalReview" />,
        subtitle: medicalReviewActive && (
          <>
            <p>
              <Translate msg="task.medicalReview.description" />
            </p>
            <p>
              <Translate msg="notification.savePhoneNumber" />
            </p>
            <FakeButton onClick={() => openVcfFile()}>
              (202) 932-9958
            </FakeButton>
          </>
        )
      },
      { status: "to-do", title: <Translate msg="task.planPersonalization" /> },
      { status: "to-do", title: <Translate msg="task.programStart" /> }
    ]
  };

  useEffect(() => {
    void removeTestStripsDraftSubscriptionCoupons();
  }, []);

  return (
    <>
      <ShipmentsList />

      {isFullSubscriptionInReview && (
        <LegacyCard>
          <div style={{ fontSize: "2.2rem", textAlign: "center" }}>
            <Emoji label="hourglass">⌛</Emoji>
          </div>
          <h4>
            <Translate msg="planOverview.reviewed" />
          </h4>
          <h4>
            <Translate msg="planOverview.information" />
          </h4>
        </LegacyCard>
      )}

      {/* Next steps card for prescryptive funnel */}
      {showNextSteps && !isCDCDPPSubscription && (
        <>
          <nine-spacer s="xs"></nine-spacer>
          <Pad>
            <NextStepsCard {...nextStepsCardProps} />
            <nine-spacer s="md"></nine-spacer>
          </Pad>
        </>
      )}

      {/* Prescription medications */}
      {showMeds && (
        <nine-info-section id="prescriptionMedications">
          <p slot="title" className="m0 strong">
            <Translate msg="prescriptionMedication" />
          </p>
          <nine-info-container slot="content">
            {parsedMeds.map((item) => {
              const isPlaceholderMed = checkItemType(
                item.externalItemType,
                ItemType.PRELIMINARY_MEDICATION_SUGGESTION
              );

              const description = isPlaceholderMed
                ? translate("plan_overview_placeholder_medication_dosage")
                : item.intake;

              const inlineWarning = item.expiredAt
                ? translate("prescription_valid_missing_lab_values_alert")
                : "";

              const showRefill =
                item.externalItemType !==
                externalItemType.PRESCRIBED_MEDICATION;

              return (
                <MedicationRowItem
                  key={item.sku}
                  sku={item.sku}
                  item={item}
                  image={pillBottle}
                  duration={
                    subscription?.renewalInterval as SubscriptionInterval
                  }
                  description={description}
                  inlineWarning={inlineWarning}
                  showRefill={showRefill}
                  hidePrice
                  hideRemove
                />
              );
            })}
          </nine-info-container>
        </nine-info-section>
      )}

      {/* Devices and supplies */}
      {(showSupplies || storedDevices.length > 0) && (
        <nine-info-section id="devicesAndSupplies">
          <p slot="title" className="m0 strong">
            <Translate msg="devicesAndSupplies" />
          </p>
          <nine-info-container slot="content">
            {parsedSupplies.map((item) => {
              const inlineWarning = item.expiredAt
                ? translate("prescription_valid_missing_lab_values_alert")
                : "";

              const sig = getMedicationItemSig(item);

              return (
                <MedicationRowItem
                  key={item.sku}
                  sku={item.sku}
                  item={item}
                  image={devicesBox}
                  description={sig}
                  inlineWarning={inlineWarning}
                  hidePrice
                  hideRemove
                />
              );
            })}
            {storedDevices.map((device) => {
              const deviceConfig = deviceTypeToImageMap[device.type];
              if (deviceConfig === null) {
                return null;
              }
              return (
                <DeviceRowItem
                  key={device.id}
                  device={device}
                  image={deviceConfig.src}
                  imageAlt={deviceConfig.alt}
                />
              );
            })}
          </nine-info-container>
        </nine-info-section>
      )}

      {/* Lab testing */}
      {showLabs && (
        <nine-info-section
          id="labTesting"
          style={
            !hasLabs
              ? {
                  visibility: "hidden",
                  height: "0",
                  overflow: "hidden",
                  margin: "0",
                  position: "absolute"
                }
              : {}
          }
        >
          <p slot="title" className="m0 strong">
            <Translate msg="labTesting" />
          </p>

          <div slot="infoModal" style={{ marginTop: "-0.2em" }}>
            <StyledReadMore
              fetchFromCms
              slug={`${
                hasPhlebotomy ? PHLEBOTOMY_SKU : BLOOD_SPOT_SKU
              }_TREATMENT_PLAN_DETAILS`}
            />
          </div>

          <nine-info-container slot="content">
            <nine-info-row
              variant="default"
              imageSrc={hasPhlebotomy ? HomePhlebotomyImage : LabTestsImage}
              imageAlt={translate(
                hasPhlebotomy
                  ? "image.alt.professionalTakingBloodSampleAtHome"
                  : "image.alt.professionalTakingBloodSampleInLab"
              )}
            >
              <h5 slot="title" className="m0 strong">
                <Translate
                  msg={
                    hasPhlebotomy
                      ? "suggestion_item_title_AT_HOME_PHLEBOTOMY_extended"
                      : "suggestion_item_title_DRY_BLOOD_SPOT_LAB_TEST_extended"
                  }
                />
              </h5>

              <p slot="description" className="m0 color-c-80">
                <Translate msg="suggestion_item_description_AT_HOME_PHLEBOTOMY_extended" />
              </p>
            </nine-info-row>
          </nine-info-container>
        </nine-info-section>
      )}

      {(showNutritionProgram || showLifestyleCoaching
        )&& (
        <nine-info-section id="lifestyleCoaching">
          <p slot="title" className="m0 strong">
            <Translate msg="lifestyleCoaching" />
          </p>
          <nine-info-container slot="content">
            {/* RxDiet nutrition program */}
            {showNutritionProgram && (
              <nine-info-row
              variant="default"
              imageSrc={RxDietThumbnailImage}
              imageAlt={translate("rxDiet.img.thumbnail.alt")}
            >
              <h5 slot="title" className="m0 strong">
                <Translate msg="rxDiet.myPlan.card.title" />
              </h5>

              <p slot="description" className="m0 color-c-80">
                <Translate msg="rxDiet.myPlan.card.subtitle" />
              </p>
            </nine-info-row>
            )}
            {showLifestyleCoaching && (
            <nine-info-row
              variant="default"
              imageSrc="https://cdn.sanity.io/images/g38rxyoc/production/23dcd6e8cff08ef0980fc452c97f97e1a6952cff-176x176.png"
              imageAlt={translate("lifestyle.title_lifebalance")}
              onClick={(): void => navigate(`/app/plan/${KnownProgram.LIFEBALANCE}`)}
            >
              <h5 slot="title" className="m0 strong">
                <Translate msg="lifestyle.title_lifebalance" />
              </h5>

              <p slot="description" className="m0 color-c-80">
                <Translate msg="lifestyle.description_lifebalance" />
              </p>
            </nine-info-row>
            )}
          </nine-info-container>
        </nine-info-section>
      )}

      {/* Access to Care Team */}
      {showAccessToCareTeam && (
        <nine-info-section id="accessToCareTeam">
          <p slot="title" className="m0 strong">
            <Translate msg="planOverview.careTeam" />
          </p>

          <div slot="infoModal" style={{ marginTop: "-0.2em" }}>
            <StyledReadMore fetchFromCms slug="CARE_TEAM" />
          </div>

          <nine-info-container slot="content">
            <nine-info-row
              variant="default"
              imageSrc={PatientCareImage}
              imageAlt={translate("image.alt.personHoldingPillBottle")}
            >
              <h5 slot="title" className="m0 strong">
                <Translate msg="suggestion_item_title_base_membership" />
              </h5>

              <p slot="description" className="m0 color-c-80">
                <Translate msg="planOverview.benefit" />
              </p>
            </nine-info-row>
          </nine-info-container>
        </nine-info-section>
      )}

      {/* Glucose testing */}
      {showTestStrips && (
        <nine-info-section id="glucoseTesting">
          <p slot="title" className="m0 strong">
            <Translate msg="glucoseTesting" />
          </p>

          <div slot="infoModal" style={{ marginTop: "-0.2em" }}>
            <StyledReadMore fetchFromCms slug="CONTOUR_NEXT_TEST_STRIPS" />
          </div>

          <nine-info-container slot="content">
            <nine-info-row
              variant="default"
              imageSrc={TestStripsAndGlucometerImage}
              imageAlt={translate("image.alt.contourNextOne")}
            >
              <h5 slot="title" className="m0 strong">
                <Translate msg="planOverview.contourTestKit" />
              </h5>

              <nine-pill slot="pill" class="hide-below-switch">
                <Translate msg="autoRefill" />
              </nine-pill>

              <p slot="description" className="m0 color-c-80">
                {translate("plan_overview_test_strips_description", {
                  count: testStripsMeasurementFrequency
                })}{" "}
                {translate("plan_overview_test_strips_title", {
                  quantity: testStripsQuantity
                })}
                .
              </p>
            </nine-info-row>
          </nine-info-container>
        </nine-info-section>
      )}
    </>
  );
};
