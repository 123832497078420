import type { FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import { DateFormats, dateLocal } from "src/lib/date";
import { DateIndicator } from "src/ui/components/AppointmentsList/components/DateIndicator";
import {
  AppointmentAction,
  MoreAppointmentAction
} from "src/ui/components/AppointmentsList/components/AppointmentAction";
import { tracker } from "src/state/state";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { APP_BREAKPOINT } from "src/constants/layout";
import AppointmentsBloc, {
  Appointment
} from "src/state/AppointmentsBloc/AppointmentsBloc";
import {
  AppPopup,
  useAppQueryPopup
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import { useBloc } from "@blac/react";
import Translate from "src/ui/components/Translate/Translate";

const AppointmentCardLayout = styled.div`
  --padding: 1rem;
  --active-background: var(--color-cream);

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    --padding: 1.5rem;
    --active-background: white;

    &:hover {
      box-shadow: var(--large-shadow);
    }
  }

  position: relative;
  transition: all 0.1s ease-in-out;
  color: inherit;
  padding: var(--padding);
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--light-shadow);

  &:active {
    background: var(--active-background);
  }

  &[data-loading="true"] {
    opacity: 0.6;
    pointer-events: none;
  }

  .display {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }

  .label {
    font-weight: 500;
    font-size: 1.25rem;
  }

  .description {
    font-size: 0.875rem;
    color: var(--color-charcoal-80);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .time {
    font-weight: 500;
    color: var(--color-charcoal-80);
  }

  .cta {
    display: flex;

    justify-content: space-between;
    gap: 1rem;
  }

  .primary-actions-wrapper {
    display: flex;
    gap: 0.5rem;
  }
`;

const AppointmentCard: FC<{
  appointment: Appointment;
}> = ({ appointment }) => {
  const [, { loadAppointments }] = useBloc(AppointmentsBloc);
  const { config, start, end, id, title, description } = appointment;

  const onPopupClose = () => {
    // reload appointments when the popup is closed
    void loadAppointments();
  };

  const [openPopup] = useAppQueryPopup(AppPopup.appointment, {
    onClose: onPopupClose
  });

  const handleClick = () => {
    tracker.track(TrackEvent.AppointmentItemClicked, {
      data: {
        "Appointment ID": id,
        "Appointment Title": title
      }
    });

    openPopup({
      additionalParameters: {
        id
      }
    });
  };

  const handlePrimaryActionClick = () => {
    config.primaryAction.onClick(appointment);
  };

  const handleSecondaryActionClick = () => {
    config.secondaryAction.onClick(appointment);
  };

  return (
    <AppointmentCardLayout>
      <div className="display" onClick={handleClick}>
        <div>
          <div className="time">
            <Translate
              msg="appointment.eventDateTime"
              variables={{
                startTime: dateLocal(start).format(
                  DateFormats.DISPLAY_TIME_ALT
                ),
                endTime: dateLocal(end).format(DateFormats.DISPLAY_TIME_ALT),
                timeZone: dateLocal().format("z")
              }}
            />
          </div>
          <div className="label">{title}</div>
        </div>

        <DateIndicator date={dateLocal(start)} />
      </div>
      {description && <div className="description">{description}</div>}

      <div className="cta">
        <div className="primary-actions-wrapper">
          <AppointmentAction
            visible={config.primaryAction.visible?.(appointment)}
            onClick={handlePrimaryActionClick}
            config={config.primaryAction}
          />

          <AppointmentAction
            visible={config.secondaryAction.visible?.(appointment)}
            onClick={handleSecondaryActionClick}
            config={config.secondaryAction}
          />
        </div>

        <MoreAppointmentAction onClick={handleClick} />
      </div>
    </AppointmentCardLayout>
  );
};
export default AppointmentCard;
