import { FC } from "react";
import React from "react";
import { Dayjs } from "dayjs";
import styled from "@emotion/styled";
import {getSupportedUserLanguage} from "src/lib/i18next";

const Frame = styled.div`
  --border-size: 2px;
  --border-radius: 0.5rem;

  position: relative;
  padding: var(--border-size);
  border-radius: var(--border-radius);
  background-clip: padding-box;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--border-radius);
    padding: var(--border-size);
    background: var(--gradient-dusk);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0) border-box;
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
  }

  display: flex;
  flex-direction: column;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  background: var(--gradient-dusk);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Month = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 1rem 0.125rem;
  line-height: 0.75rem;
  display: flex;
  align-items: center;
`;

const Day = styled.div`
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.1rem;
  font-family: var(--font-family-serif);
  
  padding: 0.5rem 1;
`;

const Divider = styled.div`
  height: var(--border-size);
  background: var(--gradient-dusk);
`;

export const DateIndicator: FC<{ date: Dayjs }> = ({ date }) => {
  const userLanguage = getSupportedUserLanguage();

  return (
    <Frame>
      <Month>{date.locale(userLanguage).format("MMM")}</Month>
      <Divider />
      <Day>{date.format("D")}</Day>
    </Frame>
  );
};
