import { Cubit } from "blac-next";
import { LoincCodingCode } from "src/constants/fhir";
import loincCodeToName from "src/lib/loincCodeToName";
import translate from "src/lib/translate";

export const VitalSignLoincCodeToNameMap = new Map([
  [LoincCodingCode.hba1c, translate(loincCodeToName(LoincCodingCode.hba1c))],
  [LoincCodingCode.weight, translate(loincCodeToName(LoincCodingCode.weight))],
  [
    LoincCodingCode.bloodPressure,
    translate(loincCodeToName(LoincCodingCode.bloodPressure))
  ],
  [
    LoincCodingCode.bloodGlucoseCapillary,
    translate(loincCodeToName(LoincCodingCode.bloodGlucoseCapillary))
  ],
  [
    LoincCodingCode.stepsInDay,
    translate(loincCodeToName(LoincCodingCode.stepsInDay))
  ],
  [
    LoincCodingCode.waistCircumference,
    translate(loincCodeToName(LoincCodingCode.waistCircumference))
  ]
]);

export interface DataHubState {
  activeVitalSignLoincCode?: LoincCodingCode;
}

export default class DataHubCubit extends Cubit<DataHubState> {
  constructor() {
    super({
      activeVitalSignLoincCode: LoincCodingCode.hba1c
    });
  }

  public readonly setActiveVitalSignLoincCode = (
    vitalSignLoincCode: LoincCodingCode
  ) => {
    this.patch({
      activeVitalSignLoincCode: vitalSignLoincCode
    });
  };
}
