import { Button } from "@9amhealth/shared";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC } from "react";
import React from "react";
import DataHubCubit, { VitalSignLoincCodeToNameMap } from "./DataHubCubit";

//#region Styled components
const TabsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  margin: -1em calc(var(--space-md) * -1) -2.5em -5em;
  padding: 2em var(--space-md) 4.25em 5em;

  //hide scrollbar all browsers
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabButton = styled(Button)`
  background: rgba(255, 255, 255, 0.26);
  min-width: max-content;
  --btn-padding: 0.5rem 1rem 0.3rem !important;

  &.active {
    background: var(
      --sunrise-horizontal-dusk,
      linear-gradient(90deg, #0e3763 0%, #5b4855 52.6%, #8a6947 100%)
    );
    color: #fff;
    --inner-opacity: 0;
  }
`;
//#endregion

const VitalSignTabs: FC = () => {
  const [{ activeVitalSignLoincCode }, { setActiveVitalSignLoincCode }] =
    useBloc(DataHubCubit);

  return (
    <TabsWrapper>
      {Array.from(VitalSignLoincCodeToNameMap).map(([key, value]) => (
        <TabButton
          hideArrow
          onPress={() => {
            setActiveVitalSignLoincCode(key);
          }}
          className={clsx({
            active: activeVitalSignLoincCode === key
          })}
        >
          <small className="strong">{value}</small>
        </TabButton>
      ))}
    </TabsWrapper>
  );
};

export default VitalSignTabs;
