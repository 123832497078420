import React, { FC } from "react";
import CollectionTitle from "../StyledComponents/CollectionTitle";
import AppointmentCard from "src/ui/components/AppointmentsList/components/AppointmentCard";
import styled from "@emotion/styled";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import { useBloc } from "@blac/react";
import Translate from "src/ui/components/Translate/Translate";

const Wrapper = styled.div`
  margin: 0 0 1.5rem;
`;

const AppointmentsList: FC = () => {
  const [{ nextAppointment }] = useBloc(AppointmentsBloc);

  if (!nextAppointment) {
    return null;
  }

  return (
    <Wrapper>
      <CollectionTitle>
        <Translate msg="appointment.list.title" />
      </CollectionTitle>

      <AppointmentCard appointment={nextAppointment} />
    </Wrapper>
  );
};

export default AppointmentsList;
